import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  Edit,
  Filter,
  List,
  ListButton,
  NumberInput,
  Pagination,
  required,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar
} from 'react-admin';

const LongPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 75, 100]} {...props} />;

const PdfFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput
      allowEmpty={false}

      source="status"
      choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'working', name: 'Working' },
        { id: 'complete', name: 'Complete' },
      ]} />
    <BooleanInput source="priority" />
  </Filter>
);

export const PdfList = ({ permissions, ...props }) => {
  return (
    <>
      <List
        {...props}
        filters={<PdfFilter />}
        perPage={25}
        pagination={<LongPagination />}
      >
        <Datagrid>
          {/* <NumberField source="id" /> */}
          <TextField source="filenumber" />
          <TextField source="username" />
          <TextField source="status" />
          <TextField source="active" />
          <TextField source="created" />
          <TextField source="started" />
          <TextField source="completed" />
          <BooleanField source="priority" />
          <BooleanField source="proficiency" label="English" />
        </Datagrid>
      </List>
    </>
  );
};


export const PdfCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput disabled source="id" />
      <TextInput source="filenumber" />
      <NumberInput required={required()} source="workerid" />
      <TextInput source="username" />
      <TextInput source="status" />
      <TextInput source="active" />
      <DateInput source="created" />
      <DateInput source="started" />
      <DateInput source="completed" />
      <BooleanInput source="priority" />
    </SimpleForm>
  </Create>
);


const PostEditActions = props => {
  const basePath = props.basePath;
  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    </TopToolbar>
  )
};

export const PdfEdit = props => {

  return (
    <Edit actions={<PostEditActions />} {...props}>
      <SimpleForm>
        <NumberInput disabled source="id" />
        <TextInput source="filenumber" />
        <NumberInput required={required()} source="workerid" />
        <TextInput source="username" />
        <TextInput source="status" />
        <TextInput source="active" />
        <DateInput source="created" />
        <DateInput source="started" />
        <DateInput source="completed" />
        <BooleanInput source="priority" />
      </SimpleForm>
    </Edit>
  );
}