import { isCancel, CancelToken } from "axios";
import React, { useMemo, useRef, useState } from 'react';
import {
  Button, Col, Container,
  Jumbotron, ProgressBar,
  Row, Alert, Form,
} from "react-bootstrap";
import { useDropzone } from 'react-dropzone';
import { Redirect } from 'react-router-dom';
import { useUserContext } from '../UserContext';
import { upload } from '../Api';
import toast from 'react-hot-toast';


export const Upload = () => {
  const { user } = useUserContext();
  const [show, setShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('danger');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);
  const [priority, setPriority] = useState(false);
  const [proficiency, setProficiency] = useState(false);


  const UploadDropzone = () => {

    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 4,
      borderRadius: 4,
      borderColor: '#cccccc',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };

    const activeStyle = {
      borderColor: '#2196f3'
    };

    const acceptStyle = {
      borderColor: '#00e676'
    };

    const rejectStyle = {
      borderColor: '#ff1744'
    };

    const {
      acceptedFiles,
      fileRejections,
      isDragActive,
      isDragAccept,
      isDragReject,
      getRootProps,
      getInputProps
    } = useDropzone({
      // accept: 'image/jpeg, image/png'
      accept: 'application/pdf',
      maxFiles: 0,
      maxSize: Infinity
    });

    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragAccept,
      isDragReject
    ]);

    const acceptedFileItems = acceptedFiles.map(file => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map(e => (
            <li key={file.name}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));

    const uploadFile = event => {
      event.preventDefault();

      let data = new FormData();
      acceptedFiles.map(file => {
        return data.append('files', file)
      });

      data.append('priority', priority);
      data.append('proficiency', proficiency);

      const options = {
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setUploadPercentage(percent);
          }
        },
        cancelToken: new CancelToken(cancel => (cancelFileUpload.current = cancel))
      };

      upload(data, options)
        .then(response => {
          setUploadPercentage(100);
          setTimeout(() => {
            setUploadPercentage(0);
          }, 1000);
          if (response?.status === 413) {
            toast.error(`Error ${response?.status}: Your upload exceeds 500 Mb please upload less files at once.`);
          }
          else if (response?.status === 400 && response.data.error.ko) {
            setAlertType("danger");
            setAlertMessage(() => {
              return (
                <>
                  <Alert.Heading>Files Rejected by server</Alert.Heading>
                  <ul>
                    {
                      response.data.error.ko.map(item => {
                        return (
                          <li>{item}</li>
                        );
                      })
                    }
                  </ul>
                </>
              );
            });
            setShow(true);
          }
          else if (response?.status === 202) {
            setAlertType("warning");
            setAlertMessage(() => {
              return (
                <>
                  <Alert.Heading>Files accepted by server</Alert.Heading>
                  <ul>
                    {
                      response.data.warning.ok.map(item => {
                        return (
                          <li>{item}</li>
                        );
                      })
                    }
                  </ul>
                  <Alert.Heading>Files rejected by server</Alert.Heading>
                  <ul>
                    {
                      response.data.warning.ko.map(item => {
                        return (
                          <li>{item}</li>
                        );
                      })
                    }
                  </ul>
                </>
              );
            });
            setShow(true);
          }
          else if (response?.status === 200) {
            toast.success("Files uploaded successfully.")
          }
          else if (response?.status === 500) {
            toast.error(`Error ${response?.status}: Server unavailable.`);
          }
          else {
            toast.error(`Error ${response?.status}: ${response.data.error}`);
          }
        })
        .catch(error => {
          if (isCancel(error)) {
            toast.error(`Upload cancelled.`);
          } else {
            toast.error(`Error: ${error}`);
          }
          setUploadPercentage(0);
        });
    };

    const cancelUpload = () => {
      if (cancelFileUpload.current) {
        cancelFileUpload.current("Upload cancelled.");
      }
    };

    return (
      <Container>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <p><em>(Only *.pdf documents will be accepted)</em></p>
          <p>Click the button Upload to start the upload</p>
        </div>
        <br />
        {uploadPercentage > 0 && (
          <>
            <ProgressBar
              animated
              now={uploadPercentage}
              striped={true}
              label={`${uploadPercentage}%`}
            />
            <br />
            <Button
              variant="danger"
              block
              onClick={() => cancelUpload()}
            >
              Cancel
            </Button>
          </>
        )}
        <Form.Row className="justify-content-center">
          <Form.Check
            inline
            custom
            name="target"
            label="Mark these files as High Priority"
            type="checkbox"
            id="priority"
            checked={priority}
            onChange={() => setPriority(!priority)}
          />
          <Form.Check
            inline
            custom
            name="target"
            label="Mark these files as requiring proficiency in English"
            type="checkbox"
            id="proficiency"
            checked={proficiency}
            onChange={() => setProficiency(!proficiency)}
          />
        </Form.Row>
        <br />
        <Button
          block
          size="lg"
          disabled={acceptedFiles.length == 0}
          onClick={uploadFile}
        >
          Upload
        </Button>
        <br />
        <aside>
          <Row>
            <Col>
              <h5>Files valid for upload</h5>
              <ul style={{ fontSize: 12 }}>{acceptedFileItems}</ul>
            </Col>
            <Col>
              <h5>Files invalid for upload</h5>
              <ul style={{ fontSize: 12 }}>{fileRejectionItems}</ul>
            </Col>
          </Row>
        </aside>
      </Container>
    );
  };

  if (!user || user?.role !== 'admin') {
    return <Redirect to="/login" />;
  };

  // Content to render
  return (
    <Container>
      {
        show
          ? (
            <Alert variant={alertType} onClose={() => setShow(false)} dismissible>
              {alertMessage}
            </Alert>
          ) : null
      }
      <Jumbotron mt={5}>
        <Col sm={12} className="mx-auto">
          <h1 className="text-center">Upload files</h1>
          <br />
          {UploadDropzone()}
        </Col>
      </Jumbotron>
    </Container >
  );
};