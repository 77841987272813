import axios from 'axios';
import rateLimit from 'axios-rate-limit';


// axios.defaults.baseURL = "https://127.0.0.1:5000";
//axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

export const axiosHttp = rateLimit(axios.create(), { maxRequests: 2, perMilliseconds: 1000, maxRPS: 2 });
let axios_token = '';

axiosHttp.defaults.withCredentials = true;
axiosHttp.defaults.baseURL = '/api';

// Interceptor when not authenticated

axiosHttp.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (
    (error?.status === 401 || error?.status === 403)
    && !originalRequest._retry
    && originalRequest.url != "auth/refresh"
  ) {
    originalRequest._retry = true;
    const access_token = axiosHttp.get('auth/refresh')
      .then(response => {
        if (response?.status === 200) {
          axios_token = access_token.data["access_token"];
        }
      })
      .catch(console.log);
    return axiosHttp(originalRequest);
  }
  return Promise.reject(error);
});

// Route not protected

export const login = user => {
  return axiosHttp
    .post('/auth/login', {
      email: user.email,
      password: user.password
    })
    .then(response => {
      if (response && response?.status === 200) {
        axios_token = response.data['access_token'];
        return response;
      }
    })
    .catch(error => { return error.response });
};

// Routes requires access token

export const register = newUser => {
  return axiosHttp
    .post('/auth/register', newUser, { headers: { 'Authorization': `Bearer ${axios_token}` } })
    .then(response => {
      if (response && response?.status === 201) {
        return response;
      }
    })
    .catch(error => { return error.response });
};

export const getpdf = () => {
  return axiosHttp
    .get('/getpdf',
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then(response => {
      if (response && response?.status === 200) {
        return response;
      }
    })
    .catch(error => { return error.response });
};

export const sendjob = (job) => {
  return axiosHttp
    .post('/sendjob', job,
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then(response => {
      if (response && response?.status === 201) {
        return response;
      }
    })
    .catch(error => { return error.response });
};

export const upload = (data, options) => {
  return axiosHttp
    .post('/admin/upload', data,
      {
        ...options,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${axios_token}`
        }
      }
    )
    .then(response => {
      if (
        response &&
        (response?.status === 200 || response?.status === 202)
      ) {
        return response;
      }
    })
    .catch(error => { return error.response });
};

export const logout = () => {
  return axiosHttp
    .delete('/auth/logout',
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .catch(error => { });
};


export const deluser = id => {
  return axiosHttp
    .delete(`/admin/deluser/${id}`,
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then(response => {
      if (response && response?.status === 200) {
        return response;
      }
    })
    .catch(error => { return error.response });
};

export const getusers = () => {
  return axiosHttp
    .get('/admin/getusers',
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then(response => {
      if (response && response?.status === 200) {
        return response;
      }
    })
    .catch(error => { return error.response });
};

export const upduser = (id, userData) => {
  return axiosHttp
    .patch(`/admin/upduser/${id}`, userData,
      {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${axios_token}`
        }
      }
    )
    .then(response => {
      if (response && response?.status === 200) {
        return response;
      }
    })
    .catch(error => { return error.response });
};

export const getreport = criteria => {
  return axiosHttp
    .post(`/admin/getreport`, criteria,
      {
        headers: {
          'Authorization': `Bearer ${axios_token}`,
          responseType: 'blob' // Important
        }
      }
    )
    .then(response => {
      if (response && response?.status === 200) {
        return response;
      }
    })
    .catch(error => { return error.response });
};

export const getnotes = criteria => {
  return axiosHttp
    .post(`/admin/getnotes`, criteria,
      {
        headers: {
          'Authorization': `Bearer ${axios_token}`,
          responseType: 'blob' // Important
        }
      }
    )
    .then(response => {
      if (response && response?.status === 200) {
        return response;
      }
    })
    .catch(error => { return error.response });
};

export const refresh = () => {
  return axiosHttp
    .get('/auth/refresh')
    .then(response => {
      if (response && response?.status === 200) {
        axios_token = response.data['access_token'];
        return response;
      }
    })
    .catch(error => { return error.response });
};