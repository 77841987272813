import { createMuiTheme } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SubjectIcon from '@material-ui/icons/Subject';
import WorkIcon from '@material-ui/icons/Work';
import simpleRestProvider from 'ra-data-simple-rest';
import * as React from "react";
import { Admin, AppBar, defaultTheme, fetchUtils, Layout, Resource } from 'react-admin';
import { Redirect } from 'react-router-dom';
import { useUserContext } from '../UserContext';
import { JobList } from './RAdmin/Jobs';
import { NoteList } from './RAdmin/Notes';
import { PdfList } from './RAdmin/Pdfs';


const MyAppBar = props => <AppBar {...props} userMenu={false} />;

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

const theme = createMuiTheme({
  ...defaultTheme,
  sidebar: {
    width: 100, // The default value is 240
    closedWidth: 55, // The default value is 55
  },
});

const myStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  margin: 0,
}

export const Database = () => {

  const { user, token } = useUserContext();

  const httpClient = (url, options) => {
    if (!options) {
      options = {};
    }
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  };

  const dataProvider = simpleRestProvider("/api/reactadmin", httpClient, "x-total-count");


  if (!user || user?.role !== 'admin') {
    return <Redirect to="/login" />;
  };

  return (
    <div style={myStyles}>
      <Admin
        dataProvider={dataProvider}
        theme={theme}
        layout={MyLayout}
      >
        <Resource
          name="pdf"
          options={{ label: 'Pdfs' }}
          icon={PictureAsPdfIcon}
          // create={PdfCreate}
          // edit={PdfEdit}
          list={PdfList}
        />
        <Resource
          name="job"
          options={{ label: 'Work' }}
          icon={WorkIcon}
          // create={JobCreate}
          // edit={JobEdit}
          list={JobList}
        />
        <Resource
          name="note"
          options={{ label: 'Data' }}
          icon={SubjectIcon}
          // create={NoteCreate}
          // edit={NoteEdit}
          list={NoteList}
        />
        {/* <Resource
                name="user"
                create={UserCreate}
                edit={UserEdit}
                list={UserList}
            /> */}
      </Admin>
    </div>
  );
};
