import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useUserContext } from './UserContext';
import { login } from './Api';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';
import jwt_decode from 'jwt-decode';

export const Login = () => {

  const history = useHistory();
  const { user, setUser } = useUserContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const loginUser = {
      email: email,
      password: password
    };

    login(loginUser)
      .then(response => {
        if (response?.status === 200) {
          const decoded = jwt_decode(response.data['access_token']);
          setUser({
            firstname: decoded?.identity.firstname,
            lastname: decoded?.identity.lastname,
            username: decoded?.identity.username,
            role: decoded?.identity.role,
            country: decoded?.identity.country,
            email: decoded?.identity.email
          });
          history.push(`/profile`);
        }
        else if (response?.status === 500) {
          toast.error(`Error ${response?.status}: Server unavailable.`);
        }
        else {
          toast.error(`Error ${response?.status}: ${response.data.error}`);
        }
      })
      .catch(error => {
        toast.error(`Error: ${error}`);
      });
  };

  if (user) {
    return <Redirect to="/profile" />;
  };

  return (
    <Container>
      <Row>
        <Col md={6} mt={5} className="mx-auto">
          <Form onSubmit={event => { handleSubmit(event) }}>
            <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
            <br />
            <Form.Group className="form-group">
              <Form.Label htmlFor="email">Email address</Form.Label>
              <Form.Control
                type="email" required
                className="form-control"
                name="email"
                placeholder="Enter email"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Control
                type="password" required
                className="form-control"
                name="password"
                placeholder="Password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </Form.Group>
            <Button
              type="submit"
              className="btn btn-lg btn-primary btn-block"
            >
              Sign in
              </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}