import { faInfoCircle, faNotesMedical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useMemo } from 'react';
import {
  Button, Col, Container, Form,
  Jumbotron, OverlayTrigger,
  Popover, Row
} from 'react-bootstrap';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Redirect } from 'react-router-dom';
import { getpdf, sendjob } from '../Api';
import { useUserContext } from '../UserContext';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import toast from 'react-hot-toast';

// https://github.com/wojtekmaj/react-pdf

export const WorkArea = () => {

  const { user } = useUserContext();
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [base64pdf, setBase64pdf] = useState('');
  const [zoom, setZoom] = useState(1.0);
  const [filename, setFilename] = useState('');
  const [saveButton, setSaveButton] = useState(true);
  const [localFile, setLocalFile] = useLocalStorage('filename', "");
  const [notes, setNotes] = useLocalStorage(localFile, []);

  const emptySoldier = {
    christian: '',
    comment: '',
    datebirth: '',
    dateevent: '',
    initials: '',
    number: '',
    photo: false,
    postnominals: '',
    rank: '',
    surname: '',
    typeevent: '',
    unit: '',
  };

  const [soldier, setSoldier] = useState(emptySoldier);

  const handleChange = event => {
    event.persist();
    setSaveButton(false);
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setSoldier(prevSoldier => ({ ...prevSoldier, [event.target.id]: value }));
  };

  useEffect(() => {
    if (JSON.stringify(soldier) === JSON.stringify(emptySoldier)) {
      setSaveButton(true);
    }
  }, [soldier]);

  const saveNote = event => {
    event.preventDefault();
    setNotes(prevNotes => ([...prevNotes, { ...soldier }]));
    setSoldier(emptySoldier);
    setSaveButton(true);
    toast.success(`Data saved, add the next soldier's data or click submit if you are finished.`, { duration: 10000 })
  };


  const submitJob = event => {
    event.preventDefault();
    let jobtosend;
    if (JSON.stringify(soldier) !== JSON.stringify(emptySoldier)) {
      jobtosend = [...notes, { ...soldier }];
    } else {
      jobtosend = [...notes];
    }
    sendjob({ "notes": jobtosend, "filename": filename })
      .then(response => {
        if (response?.status === 201) {
          toast.success(`Data submitted successfully.`, { duration: 10000 });
          setBase64pdf("");
          setFilename("");
          setLocalFile("");
          setSoldier(emptySoldier);
          setNotes([]);
          loadPdf();
        }
        else if (response?.status === 500) {
          toast.error(`Error ${response?.status}: Server unavailable.`);
        }
        else {
          toast.error(`Error ${response?.status}: ${response.data.error}`);
        }
      })
      .catch(error => {
        toast.error(`Error: ${error}`);
      });
  };

  // Hook
  function useLocalStorage(key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        return initialValue;
      }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // A more advanced implementation would handle the error case
      }
    };
    return [storedValue, setValue];
  };


  const loadPdf = () => {
    getpdf().then(response => {
      if (response?.status === 200) {
        setBase64pdf('data:application/pdf;base64,'.concat(response.data.base64pdf));
        setFilename(response.data.filename);
        if (localFile !== response.data.filename) {
          localStorage.clear();
          setNotes([]);
        }
        setLocalFile(response.data.filename);
        setNumPages(1);
      }
      else if (response?.status === 500) {
        toast.error(`Error ${response?.status}: Server unavailable.`);
      }
      else {
        toast.error(`Error ${response?.status}: ${response.data.error}`);
        localStorage.clear();
      }
    })
      .catch(error => {
        toast.error(`Error: ${error}`);
      });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }


  useEffect(() => {
    return loadPdf();
  }, []);

  if (!user) {
    return <Redirect to="/login" />;
  };

  return (
    <Container className="Container-full">
      <Jumbotron>
        <Row>
          <Col fixed="top" sm>
            <h1 className="text-center">WorkArea</h1>
            <p className="text-center font-weight-light font-italic">
              Where you enter the data relevant to a soldier in an image.
              <OverlayTrigger
                rootClose={true}
                placement="top"
                overlay={
                  <Popover id="popover-zoom">
                    <Popover.Title as="h3">WorkArea.</Popover.Title>
                    <Popover.Content>
                      If there is more than 1 soldier mentioned in an image, type the details of the first person then click <strong>Add another soldier</strong>, then type the details of the next soldier.<br />
                      Click <strong>Submit</strong> when you are finished.
                    </Popover.Content>
                  </Popover>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
              </OverlayTrigger>
            </p>
            <br />
            <Form>
              <Form.Group>
                <Form.Row>
                  <Col sm={9}>
                    <Form.Label>File name</Form.Label>
                    <Form.Control size="sm" disabled
                      type="text"
                      id="number"
                      value={filename}
                      onChange={event => setFilename(event.target.value)}
                      placeholder=''
                    />
                  </Col>
                  <Col sm={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                    <Form.Label>
                      Zoom {zoom}x &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-zoom">
                            <Popover.Title as="h3">Zoom</Popover.Title>
                            <Popover.Content>
                              To increase the size of the image that appears on the right hand side of the page.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control size="sm" custom
                      type="range"
                      id="zoom"
                      min="0.5"
                      max="3.0"
                      step="0.1"
                      value={zoom}
                      onChange={event => setZoom(Number(event.target.value))}
                    />
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  <Col sm>
                    <Form.Label>
                      Number &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-number">
                            <Popover.Title as="h3">Number</Popover.Title>
                            <Popover.Content>
                              For most newspaper write ups, the unique number each soldier has, will not be given. If no number before the soldier's name, just leave the box blank.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control size="sm" type="text" id="number" value={soldier.number} onChange={handleChange} />
                  </Col>
                  <Col sm>
                    <Form.Label>
                      Rank &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-rank">
                            <Popover.Title as="h3">Rank</Popover.Title>
                            <Popover.Content>
                              The soldier's rank will always be abbreviated to any of Pte, Cpl, Sjt, CSM, 2Lt, Lt, Capt, Maj, Lt-Col or whatever. The rank usually appears before the name.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control size="sm" type="text" id="rank" value={soldier.rank} onChange={handleChange} />
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  <Col sm={3}>
                    <Form.Label>
                      Initials &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-initials">
                            <Popover.Title as="h3">Initials</Popover.Title>
                            <Popover.Content>
                              Most papers will use initials, however when an officer is being written up they will nearly always use the Christian names. Initials usually appear with a full stop between them ie A.M. Palmer.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control size="sm" type="text" id="initials" value={soldier.initials} onChange={handleChange} />
                  </Col>
                  <Col sm={9}>
                    <Form.Label>
                      Christian names &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-christian">
                            <Popover.Title as="h3">Christian names</Popover.Title>
                            <Popover.Content>
                              The Christian Name for most of the soldiers will follow their Rank and be before their Surname eg Pte Alexander Palmer - Pte (or Private) is the Rank, Alexander is the Christian name and Palmer the Surname.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control size="sm" type="text" id="christian" value={soldier.christian} onChange={handleChange} />
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Row>
                  <Col sm>
                    <Form.Label>
                      Surname &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-surname">
                            <Popover.Title as="h3">Surname</Popover.Title>
                            <Popover.Content>
                              The surname usually follows the soldier's initials or Christian names, or sometimes appears after the rank like Pte Palmer.<br />
                              If the image only concerns the operations of a unit, then the surname can be left blank but the unit details have to be inserted into the Unit field.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control size="sm" type="text" id="surname" value={soldier.surname} onChange={handleChange} />
                  </Col>
                  <Col sm>
                    <Form.Label>
                      Post Nominals &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-unit">
                            <Popover.Title as="h3">Post Nominals</Popover.Title>
                            <Popover.Content>
                              A soldier/person may have some letters that follow his name eg V.C., D.S.O., D.C.M., M.C., M.M. which need to be inserted here.<br />
                              Usually a name is written up like Captain A.M. Palmer, VC, DSO 1st Bn Grenadier Guards. The VC, DSO are post nominal letters and need to be typed into this box.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      id="postnominals"
                      value={soldier.postnominals}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Row>
                  <Col sm>
                    <Form.Label>
                      Unit &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-unit">
                            <Popover.Title as="h3">Unit</Popover.Title>
                            <Popover.Content>
                              The Unit is the regiment that a soldier is serving with. It may be anything Argyll and Sutherland Highlanders, 1st Battalion Gloucestershire Regiment, Grenadier Guards, Royal Field Artillery, Army Service Corps, Hussars etc.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control size="sm" type="text" id="unit" value={soldier.unit} onChange={handleChange} />
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Row>
                  <Col sm>
                    <Form.Label>
                      Date of birth &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-dob">
                            <Popover.Title as="h3">Date of birth</Popover.Title>
                            <Popover.Content>
                              The date must be in the order <strong>day - month - year</strong> ie 21/2/1917 (being 21st February 1917) not in any other order.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      id="datebirth"
                      value={soldier.datebirth}
                      onChange={handleChange}
                    />

                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  <Col sm>
                    <Form.Label>
                      Type of event &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-toe">
                            <Popover.Title as="h3">Type of event</Popover.Title>
                            <Popover.Content>
                              The particular events I am looking for are when a soldier is killed, wounded, missing, prisoner of war (has been captured).
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control size="sm" as="select" id="typeevent" value={soldier.typeevent} onChange={handleChange}>
                      <option value=""></option>
                      <option value="died">Died</option>
                      <option value="killed">Killed</option>
                      <option value="missing">Missing</option>
                      <option value="prisoner">Prisoner</option>
                      <option value="wounded">Wounded</option>
                    </Form.Control>
                  </Col>
                  <Col sm>
                    <Form.Label>
                      Date of event &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-doe">
                            <Popover.Title as="h3">Date of event</Popover.Title>
                            <Popover.Content>
                              If you have selected an event eg a soldier has been Killed add the date of the event if it is stated in the image. <br />
                              The date must be in the order <strong>day - month - year</strong> ie 21/2/1917 (being 21st February 1917) not in any other order.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      id="dateevent"
                      disabled={soldier.typeevent === ''}
                      value={soldier.dateevent}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col sm style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Form.Label>
                      Photo &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-photo">
                            <Popover.Title as="h3">Photo</Popover.Title>
                            <Popover.Content>
                              If there is an image of the soldier please tick this box.
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Switch
                      id='6'
                      label='Photo available'
                      id="photo"
                      checked={soldier.photo}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Row>
                  <Col sm>
                    <Form.Label>
                      Comment &nbsp;
                      <OverlayTrigger
                        rootClose={true}
                        placement="top"
                        overlay={
                          <Popover id="popover-unit">
                            <Popover.Title as="h3">Comment</Popover.Title>
                            <Popover.Content>
                              Unless you are given precise instructions as to what to put into this text area, please insert nothing!
                          </Popover.Content>
                          </Popover>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} className="gray-info" />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      rows={3}
                      id="comment"
                      value={soldier.comment}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <br />
                <Form.Row>
                  <Col sm>
                    <OverlayTrigger
                      rootClose={true}
                      placement="top"
                      overlay={
                        <Popover id="popover-zoom">
                          <Popover.Title as="h3">Add another soldier</Popover.Title>
                          <Popover.Content>
                            Only click this button if there are multiple soldiers mentioned in an image.
                            Once you complete entering the data for the first soldier, click <strong>Add another soldier</strong> and a fresh set of text boxes will appear.<br />
                            Enter the second soldier’s data and repeat this process for all soldiers mentioned in the image.<br />
                            After you have typed the data relevant to the last soldier mentioned, click <strong>Submit
                            </strong>.<br />
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        size="md"
                        block
                        disabled={saveButton}
                        onClick={saveNote}
                      >
                        Add another soldier &nbsp;
                        <FontAwesomeIcon icon={faInfoCircle} className="info" />
                      </Button>

                    </OverlayTrigger>
                  </Col>
                  <Col sm>
                    <OverlayTrigger
                      rootClose={true}
                      placement="top"
                      overlay={
                        <Popover id="popover-zoom">
                          <Popover.Title as="h3">Submit</Popover.Title>
                          <Popover.Content>
                            After you have typed the data relevant to the last soldier mentioned in an image, click <strong>Submit</strong> and all the data you have typed for this image will be added to the database and your statistics.<br />
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Button
                        type="submit"
                        variant="danger"
                        size="md"
                        block
                        disabled={notes ? notes.length === 0 && saveButton : true}
                        onClick={submitJob}
                      >
                        Submit {notes?.length > 0 && notes?.length} &nbsp;
                        <FontAwesomeIcon icon={faInfoCircle} className="info" />
                      </Button>

                    </OverlayTrigger>
                  </Col>
                </Form.Row>
              </Form.Group>
            </Form>
          </Col>

          <Col
            sm
            className="Col-right"
            style={{
              borderLeft: 'solid 1px #000000',
              alignItems: 'flex-start',
              height: '100%',
            }}
          >


            <div className="page-controls">
              <button
                disabled={pageNumber <= 1}
                onClick={() => setPageNumber(pageNumber - 1)}
                type="button"
                aria-label="Previous page"
              >
                ‹
                  </button>
              <span>
                {pageNumber}
                {' '}
                of
                {' '}
                {numPages}
              </span>
              <button
                disabled={pageNumber >= numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
                type="button"
                aria-label="Next page"
              >
                ›
                  </button>
            </div>

            {/* <p>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</p> */}
            <Document
              file={base64pdf}
              onLoadSuccess={onDocumentLoadSuccess}
              renderMode="svg"
              width="100%"
            >

              {/* <Page scale={zoom} pageNumber={1} /> */}
              <Page scale={zoom} pageNumber={pageNumber} />

            </Document>
          </Col>
        </Row>
      </Jumbotron>
    </Container >
  );
};