import { createContext, useContext, useState, useEffect } from 'react';
import { refresh } from './Api';
import jwt_decode from 'jwt-decode';

export const UserContext = createContext(null);

export const useUserContext = () => useContext(UserContext);

export const UserWrapper = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  const refreshToken = () => {
    refresh()
      .then(response => {
        if (response?.status === 200) {
          const access_token = response.data['access_token'];
          const decoded = jwt_decode(access_token);
          setToken(access_token);
          setUser({
            firstname: decoded?.identity.firstname,
            lastname: decoded?.identity.lastname,
            username: decoded?.identity.username,
            role: decoded?.identity.role,
            country: decoded?.identity.country,
            email: decoded?.identity.email
          });
        }
        else if (response?.status === 401) { }
        else if (response?.status === 500) {
          // toast.error(`Error ${response?.status}: Server unavailable.`);
        }
        else {
          // toast.error(`Error ${response?.status}: ${response.data.error}`);
        }
      })
      .catch(error => {
        // toast.error(`Error: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    const intervalRefresh = setInterval(() => {
      refreshToken();
      console.log(`Refreshing at ${new Date().toISOString()}`);
    }, 800 * 1000);
    return () => clearInterval(intervalRefresh);
  });

  return (
    <UserContext.Provider value={{ user, setUser, token, setToken }}>
      { loading ? null : children}
    </UserContext.Provider >
  );
};