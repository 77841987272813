import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  Edit,
  Filter,
  List,
  ListButton,
  NumberInput,
  Pagination,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar
} from 'react-admin';

const LongPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 75, 100]} {...props} />;

const NoteFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const NoteList = ({ permissions, ...props }) => {
  return (
    <>
      <List
        {...props}
        filters={<NoteFilter />}
        perPage={25}
        pagination={<LongPagination />}
      >
        <Datagrid>
          {/* <NumberField source="id" /> */}
          <TextField source="filenumber" />
          <TextField source="username" />
          <TextField source="number" />
          <TextField source="rank" />
          <TextField source="unit" />
          <TextField source="surname" />
          <TextField source="postnominals" label="Post Nominals" />
          <TextField source="initials" />
          <TextField source="christian" label="Christian Names" />
          <TextField source="datebirth" label="DOB" />
          <BooleanField source="photo" />
          <TextField source="typeevent" label="Type of Event" />
          <TextField source="dateevent" label="Date of Event" />
          <TextField source="lastupdate" label="Last Update" />
          <TextField source="comment" />
        </Datagrid>
      </List>
    </>
  );
};


export const NoteCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput disabled source="id" />
      <TextInput source="username" />
      <TextInput source="filenumber" />
      <TextInput source="number" />
      <TextInput source="rank" />
      <TextInput source="unit" />
      <TextInput source="surname" />
      <TextInput source="postnominals" />
      <TextInput source="initials" />
      <TextInput source="christian" />
      <DateInput source="datebirth" />
      <BooleanInput source="photo" />
      <TextInput source="typeevent" />
      <DateInput source="dateevent" />
      <DateInput source="lastupdate" />
      <TextInput source="comment" />
    </SimpleForm>
  </Create>
);


const PostEditActions = (props) => {
  const basePath = props.basePath;
  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    </TopToolbar>
  )
};

export const NoteEdit = ({ permissions, ...props }) => {

  return (
    <Edit actions={<PostEditActions />} {...props}>
      <SimpleForm>
        <NumberInput disabled source="id" />
        <TextInput source="username" />
        <TextInput source="filenumber" />
        <TextInput source="number" />
        <TextInput source="rank" />
        <TextInput source="unit" />
        <TextInput source="surname" />
        <TextInput source="postnominals" />
        <TextInput source="initials" />
        <TextInput source="christian" />
        <DateInput source="datebirth" />
        <BooleanInput source="photo" />
        <TextInput source="typeevent" />
        <DateInput source="dateevent" />
        <DateInput source="lastupdate" />
        <TextInput source="comment" />
      </SimpleForm>
    </Edit>
  );
}