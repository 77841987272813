import React, { useEffect, useState } from 'react';
import {
  Button, ButtonGroup, Col,
  Container, Form, Jumbotron, Row
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { getusers, getnotes } from '../Api';
import { useUserContext } from '../UserContext';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';

export const Data = () => {
  const { user } = useUserContext();

  const today = new Date();
  const weekago = new Date();
  weekago.setDate(weekago.getDate() - 7);

  const [criteria, setCriteria] = useState({
    dateFrom: weekago.toISOString().slice(0, 10),
    dateUntil: today.toISOString().slice(0, 10),
    targetUser: "all",
    selectUser: "admin",
    fileNumber: ""
  });

  const [userList, setUserList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleChange = event => {
    event.persist();
    let value = event.target.value
    if (event.target.type === 'checkbox') { value = event.target.checked }
    if (event.target.type === 'select-multiple') {
      value = [];
      for (let i = 0; i < event.target.selectedOptions.length; i++) {
        value.push(event.target.selectedOptions[i].value);
      }
    }
    setCriteria(prevCriteria => ({ ...prevCriteria, [event.target.id]: value }));
  };

  const getList = () => {
    return new Promise((resolve, reject) => {
      getusers()
        .then(response => {
          if (response?.status === 200) {
            setUserList(response.data);
          } else {
            toast.error(`Error ${response?.status}: ${response.data.error}`);
          }
        })
        .catch(error => {
          toast.error(`Error: ${error}`);
        });
    });
  };

  useEffect(() => {
    return getList();
  }, []);

  const getNotes = event => {
    event.preventDefault();
    setButtonLoading(true);
    getnotes(criteria)
      .then(response => {
        setButtonLoading(false);
        if (response?.status === 200) {
          const base64file = response.data["base64file"];
          const filename = response.data["filename"];
          const contentType = response.data["content-type"];

          const byteCharacters = atob(base64file);
          const sliceSize = 512;
          let byteArrays = [];

          for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          var blob = new Blob(byteArrays, { type: contentType });
          saveAs(blob, filename);
        }
        else if (response?.status === 500) {
          toast.error(`Error ${response?.status}: Server unavailable.`);
        }
        else {
          toast.error(`Error ${response?.status}: ${response.data.error}`);
        }
      })
      .catch(error => {
        toast.error(`Error: ${error}`);
      });
  };

  if (!user || user?.role !== 'admin') {
    return <Redirect to="/login" />;
  };

  return (
    <Container>
      <Jumbotron>
        <Row>
          <Col md={6} className="mx-auto">
            <h1 className="text-center">Data</h1>
            <br />
            <Form>
              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>From:</Form.Label>
                    <Form.Control
                      type="date"
                      id="dateFrom"
                      value={criteria.dateFrom}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Until:</Form.Label>
                    <Form.Control
                      type="date"
                      id="dateUntil"
                      value={criteria.dateUntil}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>Generate for :</Form.Label>
                  </Col>
                </Form.Row>
                <Form.Row className="justify-content-center">

                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant={criteria.targetUser === "one" ? "primary" : "secondary"}
                      name="targetUser"
                      id="targetUser"
                      value="one"
                      onClick={handleChange}
                    >
                      One user
                    </Button>
                    <Button
                      variant={criteria.targetUser === "all" ? "primary" : "secondary"}
                      name="targetUser"
                      id="targetUser"
                      value="all"
                      onClick={handleChange}
                    >
                      All users
                    </Button>
                  </ButtonGroup>

                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>Select user :</Form.Label>
                  </Col>
                </Form.Row>
                <Form.Row className="justify-content-center">
                  <Col md={10}>
                    <Form.Control
                      as="select"
                      id="selectUser"
                      disabled={criteria.targetUser === "all"}
                      value={criteria.selectUser}
                      onChange={handleChange}
                    >
                      {
                        userList.map(user => {
                          return (<option key={user.id} id={user.id}>{user.username}</option>)
                        })
                      }
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>Filename (optional) :</Form.Label>
                  </Col>
                </Form.Row>
                <Form.Row className="justify-content-center">
                  <Form.Control
                    type="input"
                    name="fileNumber"
                    id="fileNumber"
                    placeholder="Specify a specific filename to look for, without the .pdf"
                    value={criteria.fileNumber}
                    onChange={handleChange}
                  />
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  <Col>
                    <Button
                      variant="primary"
                      size="lg"
                      block
                      disabled={buttonLoading}
                      onClick={event => getNotes(event)}
                    >
                      Download data
                    </Button>
                  </Col>
                </Form.Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Jumbotron>
    </Container >
  );
};

