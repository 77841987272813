import React, { useEffect, useState, useRef, createPortal } from 'react';
import {
  Button, Container,
  Form, FormCheck, Jumbotron,
  Modal, Row, Table
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { deluser, getusers, upduser } from '../Api';
import { useUserContext } from '../UserContext';
import { RegisterModal } from './RegisterModal';
import toast from 'react-hot-toast';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

export const Users = () => {
  const { user } = useUserContext();
  const [userList, setUserList] = useState([]);
  const [originalUserList, setOriginalUserList] = useState([]);
  const [editingId, setEditingId] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  // const [showAlert, setShowAlert] = useState(false);
  // const [alertMessage, setAlertMessage] = useState('');
  // const [alertType, setAlertType] = useState('danger');
  const [modalDeleteId, setModalDeleteId] = useState(1);
  const [password, setPassword] = useState('');

  const getList = () => {
    return new Promise((resolve, reject) => {
      getusers()
        .then(response => {
          if (response?.status === 200) {
            const responseUsers = response.data.map(oneuser => {
              return {
                ...oneuser,
                "password": "unchanged"
              }
            });
            setUserList(responseUsers);
            setOriginalUserList(responseUsers);
          }
          else if (response?.status === 500) {
            toast.error(`Error ${response?.status}: Server unavailable.`);
          }
          else {
            toast.error(`Error ${response?.status}: ${response.data.error}`);
          }
        })
        .catch(error => {
          toast.error(`Error: ${error}`);
        });
    });
  };

  const switchEdit = event => {
    // const idx = userList.findIndex(element => element.id == event.target.id);
    if (editingId[event.target.id] === undefined) { editingId[event.target.id] = false; }
    setEditingId(prevId => ({ ...prevId, [event.target.id]: !prevId[event.target.id] }));
  };

  const handleEdit = event => {
    const idx = userList.findIndex(element => element.id == event.target.id);
    // userList[idx][event.target.name]
    let newUserList = [...userList];
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    newUserList[idx] = { ...newUserList[idx], [event.target.name]: value }
    setUserList(newUserList);
  };

  const updateUser = event => {
    event.preventDefault();
    setEditingId(prevId => ({ ...prevId, [event.target.id]: !prevId[event.target.id] }));
    const userData = userList[userList.findIndex(element => element.id == event.target.id)]

    upduser(event.target.id, userData)
      .then(response => {
        if (response?.status === 200) {
          toast.success(`User updated successfully.`);
        }
        else if (response?.status === 500) {
          toast.error(`Error ${response?.status}: Server unavailable.`);
        }
        else {
          toast.error(`Error ${response?.status}: ${response.data.error}`);
        }
        getList();
      })
      .catch(error => {
        toast.error(`Error: ${error}`);
      });
  };

  const cancelEdit = event => {
    event.preventDefault();
    setEditingId(prevId => ({ ...prevId, [event.target.id]: !prevId[event.target.id] }));
    const idx = userList.findIndex(element => element.id == event.target.id);
    let newUserList = [...userList];
    newUserList[idx] = { ...originalUserList[idx] }
    setUserList(newUserList);
  };

  const deleteUser = event => {
    event.preventDefault();
    setShowModal(false);
    deluser(event.target.id)
      .then(response => {
        if (response?.status === 200) {
          toast.success(`User deleted successfully.`);
        }
        else if (response?.status === 500) {
          toast.error(`Error ${response?.status}: Server unavailable.`);
        }
        else {
          toast.error(`Error ${response?.status}: ${response.data.error}`);
        }
        getList();
      })
      .catch(error => {
        toast.error(`Error: ${error}`);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleRegisterClose = event => {
    setShowRegisterModal(false);
    getList();
  }

  const showModalDanger = event => {
    event.preventDefault();
    setShowModal(true);
    setModalDeleteId(event.target.id);
  };

  useEffect(() => {
    return getList();
  }, []);

  if (!user || user?.role !== 'admin') {
    return <Redirect to="/login" />;
  };

  return (
    <Container className="Container-full">
      <RegisterModal
        isOpen={showRegisterModal}
        onClose={event => handleRegisterClose(event)}
      />
      <Jumbotron>
        <h1 className="text-center">Users</h1>
        <br />
        <Row>
          <Table
            striped
            responsive
            hover
            size="sm"
            style={{ margin: 0, padding: 0, verticalAlign: 'middle', fontSize: 14 }}
          >
            <thead>
              <tr>
                <th colSpan={12}>
                  <h3>List of Users</h3>
                </th>
                <th colSpan={1}>
                  <Button
                    variant="primary"
                    onClick={event => setShowRegisterModal(true)}
                  >
                    Create User
                  </Button>
                </th>
              </tr>
              <tr>
                <>
                  <th style={{ minWidth: '1%', maxWidth: '1%' }}>ID</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>FIRSTNAME</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>LASTNAME</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>USERNAME</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>PASSWORD</th>
                  <th style={{ minWidth: '9%', maxWidth: '9%' }}>EMAIL</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>COUNTRY</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>ENGLISH</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>LAST SEEN</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>LAST WORK</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>ROLE</th>
                  <th style={{ minWidth: '5%', maxWidth: '5%' }}>STATUS</th>
                  <th style={{ minWidth: '10%', maxWidth: '10%' }}>ACTIONS</th>
                </>
              </tr>
            </thead>
            <tbody>
              {
                userList && userList.map(({ id, firstname, lastname, username, password, email, country, proficiency, last_seen, last_work, status, role }) => {
                  return (
                    <tr key={id}>
                      <td className="align-middle">{id}</td>
                      {
                        editingId[id]
                          ?
                          (
                            <>
                              <td className="align-middle">
                                <Form.Control required
                                  type="input"
                                  name="firstname"
                                  placeholder="First name"
                                  size="sm"
                                  id={id}
                                  value={firstname}
                                  onChange={event => handleEdit(event)}
                                />
                              </td>
                              <td className="align-middle">
                                <Form.Control required
                                  type="input"
                                  name="lastname"
                                  placeholder="First name"
                                  size="sm"
                                  id={id}
                                  value={lastname}
                                  onChange={event => handleEdit(event)}
                                />
                              </td>
                              <td className="align-middle">
                                <Form.Control required
                                  type="input"
                                  name="username"
                                  placeholder="First name"
                                  size="sm"
                                  id={id}
                                  value={username}
                                  onChange={event => handleEdit(event)}
                                />
                              </td>
                              <td className="align-middle">
                                <Form.Control required
                                  type="input"
                                  name="password"
                                  placeholder="Password"
                                  size="sm"
                                  id={id}
                                  value={password}
                                  onChange={event => handleEdit(event)}
                                />
                              </td>
                              <td className="align-middle">
                                <Form.Control required
                                  type="email"
                                  name="email"
                                  placeholder="Email"
                                  size="sm"
                                  id={id}
                                  value={email}
                                  onChange={event => handleEdit(event)}
                                />
                              </td>
                              <td className="align-middle">
                                <Form.Control as="select"
                                  id={id}
                                  name="country"
                                  size="sm"
                                  value={country}
                                  onChange={event => handleEdit(event)}
                                >
                                  <option value="Afganistan">Afghanistan</option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">American Samoa</option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bonaire">Bonaire</option>
                                  <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                  <option value="Brunei">Brunei</option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">Burkina Faso</option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Canary Islands">Canary Islands</option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">Cayman Islands</option>
                                  <option value="Central African Republic">Central African Republic</option>
                                  <option value="Chad">Chad</option>
                                  <option value="Channel Islands">Channel Islands</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">Christmas Island</option>
                                  <option value="Cocos Island">Cocos Island</option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Cook Islands">Cook Islands</option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Cote DIvoire">Cote DIvoire</option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Curaco">Curacao</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">Czech Republic</option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">Dominican Republic</option>
                                  <option value="East Timor">East Timor</option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">El Salvador</option>
                                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands">Falkland Islands</option>
                                  <option value="Faroe Islands">Faroe Islands</option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">French Guiana</option>
                                  <option value="French Polynesia">French Polynesia</option>
                                  <option value="French Southern Ter">French Southern Ter</option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Great Britain">Great Britain</option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Hawaii">Hawaii</option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="India">India</option>
                                  <option value="Iran">Iran</option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of Man">Isle of Man</option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea North">Korea North</option>
                                  <option value="Korea Sout">Korea South</option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Laos">Laos</option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libya">Libya</option>
                                  <option value="Liechtenstein">Liechtenstein</option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macau">Macau</option>
                                  <option value="Macedonia">Macedonia</option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">Marshall Islands</option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Midway Islands">Midway Islands</option>
                                  <option value="Moldova">Moldova</option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Nambia">Nambia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherland Antilles">Netherland Antilles</option>
                                  <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                  <option value="Nevis">Nevis</option>
                                  <option value="New Caledonia">New Caledonia</option>
                                  <option value="New Zealand">New Zealand</option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">Norfolk Island</option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau Island">Palau Island</option>
                                  <option value="Palestine">Palestine</option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">Papua New Guinea</option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Phillipines">Philippines</option>
                                  <option value="Pitcairn Island">Pitcairn Island</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">Puerto Rico</option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Republic of Montenegro">Republic of Montenegro</option>
                                  <option value="Republic of Serbia">Republic of Serbia</option>
                                  <option value="Reunion">Reunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russia">Russia</option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="St Barthelemy">St Barthelemy</option>
                                  <option value="St Eustatius">St Eustatius</option>
                                  <option value="St Helena">St Helena</option>
                                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                  <option value="St Lucia">St Lucia</option>
                                  <option value="St Maarten">St Maarten</option>
                                  <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                  <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                  <option value="Saipan">Saipan</option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="Samoa American">Samoa American</option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                  <option value="Saudi Arabia">Saudi Arabia</option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">Sierra Leone</option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">Solomon Islands</option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">South Africa</option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Swaziland">Swaziland</option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">Switzerland</option>
                                  <option value="Syria">Syria</option>
                                  <option value="Tahiti">Tahiti</option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania">Tanzania</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">Turkmenistan</option>
                                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Erimates">United Arab Emirates</option>
                                  <option value="United States of America">United States of America</option>
                                  <option value="Uraguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Vatican City State">Vatican City State</option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Vietnam">Vietnam</option>
                                  <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                  <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                  <option value="Wake Island">Wake Island</option>
                                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zaire">Zaire</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </Form.Control>
                              </td>
                              <td className="align-middle">
                                <Form.Check
                                  id={id}
                                  name="proficiency"
                                  checked={proficiency}
                                  onChange={event => handleEdit(event)}
                                  style={{ marginLeft: '5%' }}
                                />
                              </td>
                              <td className="align-middle">
                                {last_seen ? new Date(last_seen).toLocaleString() : null}
                              </td>
                              <td className="align-middle">
                                {last_work ? new Date(last_work).toLocaleString() : null}
                              </td>
                              <td className="align-middle">
                                <Form.Control as="select"
                                  id={id}
                                  name="role"
                                  size="sm"
                                  value={role}
                                  onChange={event => handleEdit(event)}
                                >
                                  <option value="user">User</option>
                                  <option value="admin">Admin</option>
                                </Form.Control>
                              </td>
                              <td className="align-middle">
                                <Form.Control as="select"
                                  id={id}
                                  name="status"
                                  size="sm"
                                  value={status}
                                  onChange={event => handleEdit(event)}
                                >
                                  <option value="active">Active</option>
                                  <option value="locked">Locked</option>
                                </Form.Control>
                              </td>
                            </>
                          )
                          :
                          (
                            <>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>{firstname}</td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>{lastname}</td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>{username}</td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}><em>{password}</em></td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>{email}</td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>{country}</td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>
                                {proficiency ? <CheckIcon /> : <ClearIcon />}
                              </td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>
                                {last_seen ? new Date(last_seen).toLocaleString() : null}
                              </td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>
                                {last_work ? new Date(last_work).toLocaleString() : null}
                              </td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>{role}</td>
                              <td className="align-middle" style={{ wordWrap: 'break-word' }}>{status}</td>
                            </>
                          )
                      }
                      <td className='actions'>
                        {
                          editingId[id]
                            ?
                            (
                              <>
                                <Button
                                  id={id}
                                  onClick={(event) => updateUser(event)}
                                  variant="info"
                                  style={{ margin: 2 }}
                                  size="sm"
                                >
                                  Save
                                </Button>
                                <Button
                                  id={id}
                                  onClick={(event) => cancelEdit(event)}
                                  variant="warning"
                                  style={{ margin: 2 }}
                                  size="sm"
                                >
                                  Cancel
                                </Button>
                              </>
                            )
                            : status !== 'deleted'
                              ?
                              (
                                <>
                                  <Button
                                    id={id}
                                    onClick={(event) => switchEdit(event)}
                                    variant="outline-info"
                                    style={{ margin: 2 }}
                                    size="sm"
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    id={id}
                                    onClick={event => showModalDanger(event)}
                                    variant="outline-danger"
                                    style={{ margin: 2 }}
                                    size="sm"
                                  >
                                    Delete
                              </Button>
                                </>
                              )
                              : null
                        }
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
          {
            showModal
              ?
              (
                <Modal show={showModal} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Delete confirmation</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you wish to delete this user with ID {modalDeleteId} ?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                  </Button>
                    <Button
                      id={modalDeleteId}
                      variant="danger"
                      onClick={event => deleteUser(event)}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              )
              : null
          }
        </Row>
      </Jumbotron>
    </Container >
  );
};