import React from 'react';
import { Container } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Route, Switch
} from 'react-router-dom';
import './App.css';
import { Landing } from './Components/Landing';
import { Login } from './Components/Login';
import { NavbarCustom } from './Components/NavbarCustom';
import { Users } from './Components/Admin/Users';
import { Reports } from './Components/Admin/Reports';
import { Data } from './Components/Admin/Data';
import { WorkArea } from './Components/Protected/WorkArea';
import { Profile } from './Components/Protected/Profile';
import { Upload } from './Components/Admin/Upload';
import { UserWrapper } from './Components/UserContext';
import { Database } from './Components/Admin/Database';
import { Toaster } from 'react-hot-toast';

const toastStyles = {
  style: {
    margin: '50px',
  },
  success: {
    style: {
      background: '#D4EDDA',
      border: '1px solid #C3E6CB',
      duration: 5000,
    },
  },
  error: {
    style: {
      background: '#F9D7DA',
      border: '1px solid #F6C6CB',
    },
  },
};

function App() {
  return (
    <UserWrapper>
      <Router>
        <div className="App">
          <NavbarCustom />
          <Toaster position="top-center" toastOptions={toastStyles} />
          <Container className="Container-full" style={{ marginTop: 'max(80px, 5%)' }}>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route path="/database" component={Database} />
              <Route path="/profile" component={Profile} />
              <Route path="/users" component={Users} />
              <Route path="/reports" component={Reports} />
              <Route path="/data" component={Data} />
              <Route path="/workarea" component={WorkArea} />
              <Route path="/upload" component={Upload} />
              <Route path="/login" component={Login} />
            </Switch>
          </Container>
        </div>
      </Router>
    </UserWrapper>
  );
};

export default App;
